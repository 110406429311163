import React from 'react';
import '../../assets/css/userPage/userList.css';
import SowTable from '../Common/SowTable';
import { MdOutlineSettings } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";

function TradesList({ trades, onSettingsClick, onDeleteClick }) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Division",
      dataIndex: "divisionName",
      key: "divisionName",
      width: "15%",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Preparation Status",
      dataIndex: "prepStatus",
      key: "prepStatus",
      width: "10%",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Preparation Date",
      dataIndex: "prepClosedDate",
      key: "prepClosedDate",
      width: "10%",
      render: (value) => <>{value ? new Date(value).toLocaleDateString() : 'N/A'}</>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (value) => <>{value === 1 ? 'Active' : 'Inactive'}</>
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (value) => <>{value ? new Date(value).toLocaleDateString() : 'N/A'}</>
    },
    {
      title: "Actions",
      key: "actions",
      width: "60px",
      render: (_, row) => (
        <div className="action-btns">
          <FaRegTrashAlt 
            className="cursor-pointer mr-2" 
            onClick={() => onSettingsClick(row)}
            title="Delete"
            size={16}
          />
          <MdOutlineSettings 
            className="cursor-pointer" 
            onClick={() => onDeleteClick(row)}
            title="View"
            size={18}
          />
        </div>       
      ),
    },
  ];

  console.log('TRADE LIST:', trades)
  return (
    // <section className="user-list-container">
    //   <h3 className="user-list-title">Trades</h3>
    //   <div className="user-table-wrapper">
    //     <table className="user-table">
    //       <thead>
    //         <tr>
    //           <th>Trade Name</th>
    //           <th>Division Name</th>
    //           <th>Preparation Status</th>
    //           <th>Preparation Closed Date</th>
    //           <th>Review Approval By</th>
    //           <th>Approval Status</th>
    //           <th>Approval Date</th>
    //           <th>Status</th>
    //           <th>Description</th>
    //           <th>Date Created</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {trades.length > 0 ? (
    //           trades.map((trade, index) => (
    //             <tr key={index}>
    //               <td>{trade.name || 'N/A'}</td>
    //               <td>{trade.divisionName || 'N/A'}</td>
    //               <td>{trade.prepStatus || 'N/A'}</td>
    //               <td>{trade.prepClosedDate ? new Date(trade.prepClosedDate).toLocaleDateString() : 'N/A'}</td>
    //               <td>{trade.reviewApprovalBy || 'N/A'}</td>
    //               <td>{trade.approvalStatus || 'N/A'}</td>
    //               <td>{trade.approvalDate ? new Date(trade.approvalDate).toLocaleDateString() : 'N/A'}</td>
    //               <td>{trade.status === 1 ? 'Active' : 'Inactive'}</td>
    //               <td>{trade.description || 'N/A'}</td>
    //               <td>{trade.created_at ? new Date(trade.created_at).toLocaleDateString() : 'N/A'}</td>
    //             </tr>
    //           ))
    //         ) : (
    //           <tr>
    //             <td colSpan="10">No trades available</td>
    //           </tr>
    //         )}
    //       </tbody>
    //     </table>
    //   </div>
    // </section>
    <SowTable
      header="Trades"
      rowKey="id"
      data={trades || []}
      columns={columns}
      bordered
    />
  );
}

export default TradesList;
