import React from "react";
import PropTypes from "prop-types";
import "../../assets/css/Common/deleteDialog.css";
import { ImWarning } from "react-icons/im";

const DeleteDialog = ({ id, title, message, onConfirm, onCancel }) => {
  return (
    <div id={id} className="dialog-overlay">
    <div className="dialog-box">
      <button className="close-button" onClick={onCancel}>&times;</button>
      <div className="dialog-content">
        <div className="dialog-icon">
          <ImWarning className="warning-icon" />
        </div>
        <h2 className="dialog-title">{title}</h2>
        <p className="dialog-message">{message}</p>
      </div>
      <div className="button-group">
        <button onClick={onCancel} className="cancel-button">
          No, Keep It.
        </button>
        <button onClick={onConfirm} className="confirm-button">
          Yes, Delete It!
        </button>
      </div>
    </div>
  </div>
  );
};

DeleteDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteDialog;
