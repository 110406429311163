import React from 'react';
import '../../assets/css/userPage/sideMenu.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function SideMenu() {
  const currentPath = window.location.pathname; 

  return (
    <div className="user-page-container">
      <aside className="sidebar">
        <div className="logo">
          <h1>MID Construction Group</h1>
        </div>
        <nav className="menu">
          <ul>
            <li>
              <a href="/dashboard" className={currentPath === '/dashboard' ? 'active' : ''}>
                <i className="fas fa-th-large"></i> Dashboard
              </a>
            </li>
            <li>
              <a href="/projects" className={currentPath === '/projects' ? 'active' : ''}>
                <i className="fas fa-folder"></i> Project
              </a>
            </li>
            <li>
              <a href="/sow-templates" className={currentPath === '/sow-templates' ? 'active' : ''}>
                <i className="fas fa-file-alt"></i> SOW Templates
              </a>
            </li>
            <li>
              <a href="/trades" className={currentPath === '/trades' ? 'active' : ''}>
                <i className="fas fa-hammer"></i> Trades
              </a>
            </li>
            <li>
              <a href="/user-list" className={currentPath === '/user-list' ? 'active' : ''}>
                <i className="fas fa-users"></i> Users
              </a>
            </li>
            <li>
              <a href="/department" className={currentPath === '/department' ? 'active' : ''}>
                <i className="fas fa-building"></i> Departments
              </a>
            </li>
            <li>
              <a href="/company" className={currentPath === '/company' ? 'active' : ''}>
                <i className="fas fa-home"></i> Company
              </a>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  );
}

export default SideMenu;
