import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { createUser } from '../../api/userPage/user';
import '../../assets/css/userPage/userModal.css';

function UserModal({ isOpen, onClose }) {
  const [newUser, setNewUser] = useState({
    fullName: '',
    lastName: '',
    email: '',
    password: '',
    roleType: '',
    phoneNumber: '',
    role_id: '',
    department_id: '',
    authorizeAllProject: false,
    country: '',
    zip: '',
    business_phone: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const handlePhoneChange = (phone) => {
    setNewUser((prev) => ({ ...prev, phoneNumber: phone }));
  };

  const handleAddUser = async () => {
    const userData = {
      name: newUser.fullName.trim(),
      last_name: newUser.lastName.trim(),
      email: newUser.email.trim(),
      password: newUser.password.trim(),
      role_id: Number(newUser.role_id),
      role_type: newUser.roleType,
      department_id: Number(newUser.department_id),
      phoneNumber: newUser.phoneNumber,
      authorizeAllProject: newUser.authorizeAllProject,
      country: newUser.country.trim(),
      zip: newUser.zip.trim(),
      business_phone: newUser.business_phone.trim()
    };

    if (
      !userData.name ||
      !userData.last_name ||
      !userData.email ||
      !userData.password ||
      !userData.role_id ||
      !userData.department_id ||
      !userData.country ||
      !userData.zip ||
      !userData.business_phone ||
      typeof userData.role_id !== "number" ||
      typeof userData.department_id !== "number"
    ) {
      alert('Please fill all the required fields with valid data.');
      return;
    }

    try {
      await createUser(userData);
      alert('User added successfully');
      onClose();
    } catch (error) {
      console.error('Error adding user:', error.response?.data || error.message);
      alert(error.message || "An error occurred.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">×</button>
        <h2>Add New User</h2>
        
        <div className="form-group">
          <label>Full Name</label>
          <input name="fullName" placeholder="Enter Full Name" value={newUser.fullName} onChange={handleChange} className="modal-input" required />
        </div>
        
        <div className="form-group">
          <label>Last Name</label>
          <input name="lastName" placeholder="Enter Last Name" value={newUser.lastName} onChange={handleChange} className="modal-input" required />
        </div>
        
        <div className="form-group">
          <label>Email</label>
          <input name="email" placeholder="Enter Email" type="email" value={newUser.email} onChange={handleChange} className="modal-input" required />
        </div>
        
        <div className="form-group">
          <label>Password</label>
          <input name="password" placeholder="Enter Password" type="password" value={newUser.password} onChange={handleChange} className="modal-input" required />
        </div>

        <div className="form-group">
          <label>Role Type</label>
          <select name="roleType" value={newUser.roleType} onChange={handleChange} className="modal-input" required>
            <option value="" disabled>Select Role Type</option>
            <option value="1">Admin</option>
            <option value="2">User</option>
          </select>
        </div>

        <div className="form-group">
          <label>Role ID</label>
          <input name="role_id" placeholder="Enter Role ID" type="number" value={newUser.role_id} onChange={handleChange} className="modal-input" required />
        </div>

        <div className="form-group">
          <label>Department ID</label>
          <input name="department_id" placeholder="Enter Department ID" type="number" value={newUser.department_id} onChange={handleChange} className="modal-input" required />
        </div>

        <div className="form-group">
          <label>Phone Number</label>
          <PhoneInput country={'us'} value={newUser.phoneNumber} onChange={handlePhoneChange} inputClass="phone-input-custom" containerClass="phone-input-container-custom" buttonClass="phone-input-button" required />
        </div>

        <div className="form-group">
          <label>Country</label>
          <input name="country" placeholder="Enter Country" value={newUser.country} onChange={handleChange} className="modal-input" required />
        </div>

        <div className="form-group">
          <label>ZIP Code</label>
          <input name="zip" placeholder="Enter ZIP Code" value={newUser.zip} onChange={handleChange} className="modal-input" required />
        </div>

        <div className="form-group">
          <label>Business Phone</label>
          <input name="business_phone" placeholder="Enter Business Phone" value={newUser.business_phone} onChange={handleChange} className="modal-input" required />
        </div>

        <div className="form-group">
          <label>Authorize All Projects</label>
          <input name="authorizeAllProject" type="checkbox" checked={newUser.authorizeAllProject} onChange={handleChange} />
        </div>

        <button onClick={handleAddUser} className="add-button">Add</button>
      </div>
    </div>
  );
}

export default UserModal;
