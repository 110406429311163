import React from 'react';
import { Bar, Pie, Line, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Header from '../../components/userPage/header';
import SideMenu from '../../components/userPage/sideMenu';
import '../../assets/css/Dashboard/dashboard.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const colors = {
    blue: '#007bff',
    lightBlue: '#36A2EB',
    skyBlue: '#4BC0C0',
    whiteBlue: '#D6EFFF',
    darkBlue: '#003f7f',
  };

  const barData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Progress',
        backgroundColor: colors.lightBlue,
        borderColor: colors.blue,
        borderWidth: 1,
        data: [10, 20, 30, 40, 50, 60],
      },
    ],
  };

  const pieData = {
    labels: ['Approved', 'Under Review', 'Pending'],
    datasets: [
      {
        label: 'SOW Status',
        data: [50, 25, 25],
        backgroundColor: [colors.blue, colors.lightBlue, colors.skyBlue],
      },
    ],
  };

  const lineData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Milestones',
        data: [5, 15, 10, 20, 25, 30],
        fill: true,
        backgroundColor: colors.whiteBlue,
        borderColor: colors.blue,
        tension: 0.3,
      },
    ],
  };

  const doughnutData = {
    labels: ['Electrical', 'Plumbing', 'Roofing', 'Flooring'],
    datasets: [
      {
        data: [40, 30, 20, 10],
        backgroundColor: [colors.skyBlue, colors.lightBlue, colors.blue, colors.darkBlue],
      },
    ],
  };

  return (
    <div className="dashboard-layout">
      <SideMenu />
      <div className="main-content">
        <Header title="Main Dashboard" showSearch={false} showAddButton={false}  className="dashboard-header"
        />
        <div className="dashboard-content">
          <div className="dashboard-grid">
            <div className="card square">
              <h2>Project Progress Overview</h2>
              <Bar data={barData} />
            </div>
            <div className="card square">
              <h2>SOW Status Breakdown</h2>
              <Pie data={pieData} />
            </div>
            <div className="card square">
              <h2>Project Progress Overview</h2>
              <Doughnut data={doughnutData} />
            </div>
            <div className="card rectangle">
              <h2>Timeline & Milestones</h2>
              <Line data={lineData} />
            </div>
            <div className="card rectangle">
              <h2>SOW Template Monitoring For Each Category</h2>
              <Bar data={barData} />
            </div>
            <div className="card wide">
              <h2>Cost Analysis Average per Building Type</h2>
              <Line data={lineData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
