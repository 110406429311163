import React, { useState } from "react";
import "../../assets/css/Sow/inputFieldsComponent.css";

const InputFieldsComponent = () => {
  const [formData, setFormData] = useState({
    subcontractValue: "",
    buildingType: "",
    alternateValue1: "",
    alternateValue2: "",
    rentableArea: "",
    ratePerSqFt: "",
    division: "",
    tradeScopeOfWork: "",
    subcontractNo: "",
    phaseNo: "",
    revisionNo: "",
    costCodeNo: "",
    subcontractor: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <div className="input-fields-wrapper">
      <div className="input-fields-section">
        <div className="input-group">
          <label htmlFor="subcontractValue">Subcontract Value</label>
          <input
            type="number"
            id="subcontractValue"
            placeholder="Enter Value"
            value={formData.subcontractValue}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="buildingType">Building Type</label>
          <select
            id="buildingType"
            value={formData.buildingType}
            onChange={handleInputChange}
          >
            <option value="">Select Type</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="industrial">Industrial</option>
            <option value="mixed-use">Mixed-Use</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="alternateValue1">Alternate Subcontract Value No. 1</label>
          <input
            type="number"
            id="alternateValue1"
            placeholder="Enter Value"
            value={formData.alternateValue1}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="alternateValue2">Alternate Subcontract Value No. 2</label>
          <input
            type="number"
            id="alternateValue2"
            placeholder="Enter Value"
            value={formData.alternateValue2}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="rentableArea">Rentable Area (Sq Ft)</label>
          <input
            type="number"
            id="rentableArea"
            placeholder="Enter Area"
            value={formData.rentableArea}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="ratePerSqFt">Rate per Square Feet</label>
          <input
            type="number"
            id="ratePerSqFt"
            placeholder="Enter Rate"
            value={formData.ratePerSqFt}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="division">Division</label>
          <input
            type="text"
            id="division"
            placeholder="Enter Division"
            value={formData.division}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="tradeScopeOfWork">Trade / Scope of Work</label>
          <textarea
            id="tradeScopeOfWork"
            placeholder="Enter Scope of Work"
            value={formData.tradeScopeOfWork}
            onChange={handleInputChange}
            rows="3"
          ></textarea>
        </div>
        <div className="input-group">
          <label htmlFor="subcontractNo">Subcontract No</label>
          <input
            type="text"
            id="subcontractNo"
            placeholder="Enter Subcontract No"
            value={formData.subcontractNo}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="phaseNo">Phase No</label>
          <input
            type="text"
            id="phaseNo"
            placeholder="Enter Phase No"
            value={formData.phaseNo}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="revisionNo">Revision No</label>
          <input
            type="text"
            id="revisionNo"
            placeholder="Enter Revision No"
            value={formData.revisionNo}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="costCodeNo">Cost Code No</label>
          <input
            type="text"
            id="costCodeNo"
            placeholder="Enter Cost Code No"
            value={formData.costCodeNo}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="subcontractor">Subcontractor</label>
          <input
            type="text"
            id="subcontractor"
            placeholder="Enter Subcontractor"
            value={formData.subcontractor}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default InputFieldsComponent;
