import React from "react";
import '../../assets/css/Sow/sowTemplatViewer.css'



const TemplateViewer = ({ templateItems }) => {
  return (
    <div className="template-viewer-container">
      {/* הטופס המרכזי */}
      <div className="template-viewer">
        <div className="viewer-header">
          <h2>Plumbing SOW Template</h2>
        </div>

        {/* לוגו ותיאור חברה */}
        <div className="company-details">
          <div className="logo-placeholder">[LOGO]</div>
          <div className="company-description">
            <p>Company Name: Cleveland Cabinets</p>
            <p>Address: 123 Example Street, Cleveland, OH</p>
          </div>
        </div>

        {/* תוכן דינמי */}
        <div className="viewer-content">
          {templateItems.length > 0 ? (
            templateItems.map((item, index) => (
              <div key={index} className="template-item">
                {renderItem(item)}
              </div>
            ))
          ) : (
            <p>Drag items here to create your template.</p>
          )}
        </div>
      </div>

      {/* הכפתורים במרכז הדף מתחת לטופס */}
      <div className="viewer-footer">
        <button className="page-btn">
          <i className="fas fa-plus"></i> Page
        </button>
        <button className="save-btn">Save</button>
      </div>
    </div>
  );
};

/* פונקציה להצגת פריטים */
const renderItem = (item) => {
  switch (item.type) {
    case "Title":
      return <h1 style={{ fontSize: item.settings.fontSize }}>{item.content}</h1>;
    case "DateTime":
      return <p>{item.content}</p>;
    case "List":
      return (
        <ul>
          {item.content.map((listItem, idx) => (
            <li key={idx}>{listItem}</li>
          ))}
        </ul>
      );
    case "Signature":
      return (
        <div style={{ border: "1px solid #ccc", padding: "10px", height: "50px" }}>
          {item.content}
        </div>
      );
    case "Text":
      return <p>{item.content}</p>;
    default:
      return <p>Unknown item</p>;
  }
};

export default TemplateViewer;

// const TemplateViewer = () => {
//   return (
//     <div className="template-viewer-container">
//       {/* הטופס המרכזי */}
//       <div className="template-viewer">
//         <div className="viewer-header">
//           <h2>Plumbing SOW Template</h2>
//         </div>

//         {/* לוגו ותיאור חברה */}
//         <div className="company-details">
//           <div className="logo-placeholder">[LOGO]</div>
//           <div className="company-description">
//             <p>Company Name: Cleveland Cabinets</p>
//             <p>Address: 123 Example Street, Cleveland, OH</p>
//           </div>
//         </div>

//         <div className="viewer-content">
//           <div className="centered-content">
//             <h3>Cleveland Cabinets</h3>
//             <h4>CONTRACT AMENDMENT</h4>
//             <p>DATE: July 8, 2024</p>
//             <div className="divider"></div>
//           </div>

//           <ul>
//             <li>EXHIBIT C: SCHEDULE OF VALUES</li>
//             <li>Amended Specifically for I K D Inc dated July 4, 2024</li>
//           </ul>

//           {/* כותרת עם מספר ורשימה */}
//           <div className="numbered-section">
//             <h5>1. Agreement Terms</h5>
//             <ul>
//               <li>Item A: Description of term A.</li>
//               <li>Item B: Description of term B.</li>
//               <li>Item C: Description of term C.</li>
//             </ul>
//           </div>
//         </div>
//       </div>

//       {/* הכפתורים במרכז הדף מתחת לטופס */}
//       <div className="viewer-footer">
//         <button className="page-btn">
//           <i className="fas fa-plus"></i> Page
//         </button>
//         <button className="save-btn">Save</button>
//       </div>
//     </div>
//   );
// };

// export default TemplateViewer;
