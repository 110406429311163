import React, { useState } from 'react';
import '../../assets/css/userPage/userModal.css';
import { createTrade } from '../../api/Trades/trades';

function AddTrade({ isOpen, onClose }) {
  const [tradeData, setTradeData] = useState({
    name: '',
    project_name: '',
    divisionName: '',
    subcontract_id: '',
    phase_no: '',
    cost_code_tier2: '',
    underPrepBy: '',
    prepStatus: '',
    prepClosedDate: '',
    reviewApprovalBy: '',
    approvalStatus: '',
    approvalDate: '',
    status: '1',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTradeData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddTrade = async () => {
    const tradePayload = {
      ...tradeData,
      status: Number(tradeData.status),
      prepClosedDate: tradeData.prepClosedDate || null,
      approvalDate: tradeData.approvalDate || null,
    };

    if (!tradePayload.name || !tradePayload.project_name) {
      alert('Please fill the required fields: Trade Name and Project Name.');
      return;
    }

    try {
      await createTrade(tradePayload);
      alert('Trade added successfully!');
      onClose();
    } catch (error) {
      console.error('Error adding trade:', error.response?.data || error.message);
      alert('Failed to add trade. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">×</button>
        <h2>Add New Trade</h2>

        <div className="form-group">
          <label>Trade Name *</label>
          <input
            name="name"
            placeholder="Enter Trade Name"
            value={tradeData.name}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Project Name *</label>
          <input
            name="project_name"
            placeholder="Enter Project Name"
            value={tradeData.project_name}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Division Name</label>
          <input
            name="divisionName"
            placeholder="Enter Division Name"
            value={tradeData.divisionName}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Subcontract ID</label>
          <input
            name="subcontract_id"
            placeholder="Enter Subcontract ID"
            value={tradeData.subcontract_id}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Phase No</label>
          <input
            name="phase_no"
            placeholder="Enter Phase No"
            value={tradeData.phase_no}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Cost Code Tier 2</label>
          <input
            name="cost_code_tier2"
            placeholder="Enter Cost Code Tier 2"
            value={tradeData.cost_code_tier2}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Under Prep By</label>
          <input
            name="underPrepBy"
            placeholder="Enter Under Prep By"
            value={tradeData.underPrepBy}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Preparation Status</label>
          <input
            name="prepStatus"
            placeholder="Enter Preparation Status"
            value={tradeData.prepStatus}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Preparation Closed Date</label>
          <input
            name="prepClosedDate"
            type="date"
            value={tradeData.prepClosedDate}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Review Approval By</label>
          <input
            name="reviewApprovalBy"
            placeholder="Enter Reviewer Name"
            value={tradeData.reviewApprovalBy}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Approval Status</label>
          <input
            name="approvalStatus"
            placeholder="Enter Approval Status"
            value={tradeData.approvalStatus}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Approval Date</label>
          <input
            name="approvalDate"
            type="date"
            value={tradeData.approvalDate}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Status *</label>
          <select
            name="status"
            value={tradeData.status}
            onChange={handleChange}
            className="modal-input"
            required
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            placeholder="Enter Description"
            value={tradeData.description}
            onChange={handleChange}
            className="modal-input"
            style={{ resize: 'none', height: '80px' }}
          />
        </div>

        <button onClick={handleAddTrade} className="add-button">Add Trade</button>
      </div>
    </div>
  );
}

export default AddTrade;
