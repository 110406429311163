import React from "react";
import '../../assets/css/Sow/sowForm.css';

const FormComponent = () => {
  return (
    <div className="form-section">
      <iframe
        src="/SOW_Templates.pdf" // נתיב יחסי לקובץ PDF בתיקיית public
        className="pdf-viewer"
        title="SOW Templates PDF"
      ></iframe>
    </div>
  );
};

export default FormComponent;
