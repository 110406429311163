import apiClient from './apiClient'; // ייבוא ה-axios instance עם האינטרספטור

export const checkProtectedRoute = async () => {
  const token = localStorage.getItem('accessToken'); // שולף את ה-accessToken מה-localStorage
  console.log('Access Token:', token);  // בדיקת ה-token לפני השליחה
  const response = await apiClient.get(`/api/protected-route`, {  // תיקון הנתיב
    headers: {
      Authorization: `Bearer ${token}`, // מוסיף את הכותרת Authorization עם ה-token
    },
  });

  return response.data; // מחזיר את המידע שהתקבל מהשרת
};

