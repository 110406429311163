import React from 'react';

export const Branding = () => {
  return (
    <div className="branding">
      <h1>MID Construction Group</h1>
      <p>Building The Future Of Los Angeles</p>
    </div>
  );
};
