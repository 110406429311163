import React, { useState, useEffect, useCallback } from 'react';
import '../../assets/css/project/project.css';
import Header from '../../components/userPage/header';
import SideMenu from '../../components/userPage/sideMenu';
import Pagination from '../../components/userPage/pagination';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { searchProjects, deleteProject } from '../../api/project/project';
import ProjectModal from '../../components/project/projectModal';
import ProjectList from '../../components/project/projectList';
import { useNavigate } from 'react-router-dom';
import DeleteDialog from '../../components/Common/DeleteDialog';
import ProjectGrid from '../../components/project/projectGrid';
import { FaList } from "react-icons/fa";
import { FiGrid } from "react-icons/fi";
import SettingsModal from "../../components/Common/SettingsModal";

function Project() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isGridView, setGridView] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const fetchCompanies = useCallback(async () => {
    try {
      const data = await searchProjects(searchTerm, currentPage); 
      setProjects(data.data);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  },[currentPage, searchTerm]);

  useEffect(() => {
    fetchCompanies();
  }, [searchTerm, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  
  const handleView = (projectId) => {
    navigate(`/project/${projectId}`);
  }

  const handleDeleteProject = async (project) => {
    setSelectedProject(project);
    setShowDeleteConfirm(true);
  }

  const onConfirmDelete = useCallback( async () => {
    try {
      const res = await deleteProject(selectedProject?.id); 
      if (res.success) {
        onCancelDelete();
        fetchCompanies();
      }
    } catch (e) {
      console.log('Error deleting project', e)
    }
  }, [fetchCompanies, selectedProject?.id]);

  const onCancelDelete = () => {
    setSelectedProject(null);
    setShowDeleteConfirm(false);
  }

  const handleOpenSettings = (project) => {
    setSelectedProject(project);
    setIsSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setSelectedProject(null);
    setIsSettingsOpen(false);
  };
  
  return (
    <div className="project-page-layout">
      <SideMenu />
      <div className="project-content-wrapper">
      <Header 
          title="Project" 
          addButtonLabel="Add New Project" 
          searchPlaceholder="Search Project" 
          searchValue={searchTerm} 
          ModalComponent={ProjectModal}
          onSearchChange={handleSearchChange}
        />
        <div className="view-toggle">
          <button
            className={`toggle-button ${!isGridView ? "active" : ""}`}
            onClick={() => setGridView(false)}
            title="Toggle List View"
          >
            <FaList size={20} />
          </button>
          <button
            className={`toggle-button ${isGridView ? "active" : ""}`}
            onClick={() => setGridView(true)}
            title="Toggle Grid View"
          >
            <FiGrid size={20} />
          </button>
        </div>
        {isGridView ? (
          <ProjectGrid
            projects={projects} 
            onSettingsClick={handleOpenSettings} 
            handleView={handleView}
            handleDeleteProject={handleDeleteProject}
          />
        ) : (
          <ProjectList 
            projects={projects} 
            onSettingsClick={handleOpenSettings} 
            handleView={handleView} 
            handleDeleteProject={handleDeleteProject}
          />
        )}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      {showDeleteConfirm && (
        <DeleteDialog 
          id={selectedProject?.id || ''}
          title='Delete Project'
          message={`Are you sure you want to delete ${selectedProject?.project_name || ''}?`}
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      )}
      {isSettingsOpen && selectedProject && (
          <SettingsModal
            isOpen={isSettingsOpen}
            onClose={handleCloseSettings}
            entity={selectedProject}
            entityType="User"
            fields={[
              {
                name: "project_name",
                label: "Project Name",
                type: "text"
              },
              { 
                name: "address", 
                label: "Enter Address", 
                type: "text"
              },
              { 
                name: "lotArea", 
                label: "Enter Lot Area", 
                type: "number"
              },
              { 
                name: "gross_building_up_area", 
                label: "Enter Gross Built Up Area", 
                type: "number"
              },
              { 
                name: "rentable_area_residential", 
                label: "Enter Rentable Area - Residential", 
                type: "number"
              },
              { 
                name: "rentable_area_commercial", 
                label: "Enter Rentable Area - Commercial", 
                type: "number"
              },
              { 
                name: "number_of_floors", 
                label: "Enter Number of Floors", 
                type: "number"
              },
              { 
                name: "image_thumbnail", 
                label: "Thumbnail", 
                type: "file"
              },
              { 
                name: "sow_count_completed", 
                label: "Enter SOW Count Completed", 
                type: "number"
              },
              { 
                name: "sow_count_pending", 
                label: "Enter SOW Count Pending", 
                type: "number"
              },
              { 
                name: "project_users", 
                label: "Project Users (comma-separated):", 
                type: "number"
              },
              { 
                name: "status", 
                label: "Status", 
                type: "select",
                options: [
                  { value: true, label: "Completed" },
                  { value: false, label: "Not Completed" },
                ],
              },
            ]}
            onUpdate={() => setProjects((prev) =>
              prev.map((item) =>
                item.id === selectedProject.id
                  ? { ...item, ...selectedProject }
                  : item
              )
            )}
          />
        )}
    </div>
    
  );
}

export default Project;
