import apiClient from '../login/apiClient';

export const createCompany = async (companyData) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  if (!companyData.name || !companyData.address || !companyData.email) {
    alert('Missing required fields: name, address, and email.');
    return;
  }

  try {
    const response = await apiClient.post('/api/companies', companyData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Company created successfully:', response.data);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to create company');
  }
};

export const searchCompany = async (searchTerm) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.get(`/api/companies/search?name=${searchTerm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Companies fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch companies');
  }
};

const handleError = (error, defaultMessage) => {
  if (error.response) {
    if (error.response.status === 401) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('accessToken'); 
      window.location.href = '/login'; 
      return;
    }
    console.error('Server Error:', error.response);
    alert(`Error: ${error.response.data.message || defaultMessage}`);
    return { success: false, error: error.response.data };
  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please try again later.');
    return { success: false, error: 'No response from the server' };
  } else {
    console.error('Error setting up request:', error.message);
    alert(`Request setup failed: ${error.message}`);
    return { success: false, error: error.message };
  }
};
