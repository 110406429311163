import axios from 'axios';
import { BASE_URL } from '../../config'; // ה-Base URL מוגדר בקובץ config
import { refreshToken } from './refreshToken'; // פונקציה לריענון הטוקן


const apiClient = axios.create({
  baseURL: BASE_URL,
});


apiClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});


apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    console.log('Error occurred:', error.response?.status);

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
      
        const newAccessToken = await refreshToken();
        console.log('New access token received:', newAccessToken);

      
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;


        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);

       
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
