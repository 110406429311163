import React, { useState, useEffect } from 'react';
import '../../assets/css/userPage/userPage.css';
import Pagination from '../../components/userPage/pagination';
import Header from '../../components/userPage/header';
import SideMenu from '../../components/userPage/sideMenu';
import CompaniesList from '../../components/Company/companiesList';
import CompanyModal from '../../components/Company/companyModal'; 
import { searchCompany } from '../../api/Companies/company';

function Company() {
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const data = await searchCompany(searchTerm, currentPage); 
        setCompanies(data.data);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, [searchTerm, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="user-page-layout">
      <SideMenu />
      <div className="content-wrapper">
        <Header
          title="Company"
          addButtonLabel="Add New Company"
          searchPlaceholder="Find Company"
          searchValue={searchTerm}
          onSearchChange={handleSearchChange}
          ModalComponent={CompanyModal}
        />
        <CompaniesList companies={companies || []} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Company;
