import apiClient from '../login/apiClient';

export const createTrade = async (tradeData) => {

  try {
    const response = await apiClient.post('/api/trades', tradeData, );

    console.log('Trade created successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to create trade');
  }
};

export const searchTrade = async (searchTerm) => {

  try {
    const response = await apiClient.get(`/api/trades/search?query=${searchTerm}`, {
    });

    console.log('Trades fetched successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to fetch trades');
  }
};

export const updateTrade = async (tradeId, updatedData) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/trades/${tradeId}`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Trade updated successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to update trade');
  }
};


export const deleteTrade = async (tradeId) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.delete(`/api/trades/${tradeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Trade deleted successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to delete trade');
  }
};


const handleError = (error, defaultMessage) => {
  if (error.response) {
    console.error('Server Error:', error.response.status, error.response.data);
    alert(`Server Error: ${error.response.data.message || defaultMessage}`);
    throw new Error(error.response.data.message || defaultMessage);

  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please check your network connection.');
    throw new Error('No response from the server');

  } else {
    console.error('Error in setting up request:', error.message);
    alert(`Error in setting up request: ${error.message}`);
    throw new Error(error.message);
  }
};

