import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import '../../assets/css/login/branding.css';
import '../../assets/css/login/layout.css';
import '../../assets/css/login/login.css';
import { Branding } from '../../components/login/branding';
import { LoginForm } from '../../components/login/loginForm';
import {  useNavigate } from 'react-router-dom';




const GOOGLE_CLIENT_ID = 'scope-of-work-sow'; 

export const LoginPage = () => {
  const navigate =useNavigate()
  const handleGoogleSuccess = (response) => {
    navigate('/user-list')

    console.log('Google Login Success:', response);
   
  };

  const handleGoogleFailure = (response) => {
    
    console.log('Google Login Failed:', response);
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="container">
        <div className="left-side">
          <Branding />
        </div>
        <div className="right-side">
          <div className="login-box">
            <h2>Hello Again!</h2>
            <p>Welcome Back</p>
            <LoginForm 
              handleGoogleSuccess={handleGoogleSuccess} 
              handleGoogleFailure={handleGoogleFailure} 
            />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};
