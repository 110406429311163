import React, { useState, useEffect } from 'react';
import '../../assets/css/userPage/userPage.css';
import Pagination from '../../components/userPage/pagination';
import Header from '../../components/userPage/header';
import SideMenu from '../../components/userPage/sideMenu';
import DepartmentsList from '../../components/Department/departmentsList';
import DepartmentModal from '../../components/Department/departmentModal'; 
import { searchDepartment } from '../../api/Department/department';

function Department() {
  const [searchTerm, setSearchTerm] = useState('');
  const [departments, setDepartments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const data = await searchDepartment(searchTerm, currentPage);
        setDepartments(data.data);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, [searchTerm, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="user-page-layout">
      <SideMenu />
      <div className="content-wrapper">
        <Header
          title="Departments"
          addButtonLabel="Add New Department"
          searchPlaceholder="Find Department"
          searchValue={searchTerm}
          onSearchChange={handleSearchChange}
          ModalComponent={DepartmentModal}
        />
        <DepartmentsList departments={departments || []} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Department;
