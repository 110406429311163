import React, { useState } from 'react';
// import '../../assets/css/project/header.css';
import '../../assets/css/project/projectModal.css'
import { createproject } from '../../api/project/project';
import '../../assets/css/userPage/userModal.css';

function ProjectModal({ isOpen, onClose }) {
  const [previewImage, setPreviewImage] = useState(null); 
  const [newProject, setNewProject] = useState({
    project_name: '',
    lotArea:'',
    address: '',
    number_of_floors:'',
    gross_building_up_area:'',
    rentable_area_residential: '',
    rentable_area_commercial: '',
    image_thumbnail: '',
    status:'',
    sow_count_completed: '',
    sow_count_pending: '',
    project_users: '',
    creatorId:'',
    deletedAt: '',
    deletedBy: '', 
    createdAt: '',
    updatedBy: '',
    updatedAt: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result); 
        setNewProject((prev) => ({
          ...prev,
          image_thumbnail: reader.result, 
        }));
      };
      reader.readAsDataURL(file); 
    }
  };

  const handleAddProject = async () => {
    const projectData = {
      project_name: newProject.project_name,
      address: newProject.address,
      lotArea: Number(newProject.lotArea.trim()) || 0,
      number_of_floors: Number(newProject.number_of_floors.trim())|| 0,

      gross_building_up_area:Number(newProject.gross_building_up_area.trim())  || 0,
      rentable_area_residential: Number(newProject.rentable_area_residential.trim()) || 0,
      rentable_area_commercial:Number( newProject.rentable_area_commercial.trim())|| 0,
      // total_rentable_area: Number(newProject.total_rentable_area.trim()) || 0,
      image_thumbnail: newProject.image_thumbnail, // שמירת התמונה)
      sow_count_completed:Number( newProject.sow_count_completed.trim() )|| 0,
      sow_count_pending:Number( newProject.sow_count_pending.trim())|| 0,
      // project_users: Array.isArray(newProject.project_users)
      //   ? newProject.project_users
      //   : newProject.project_users.split(','),
      project_users: Number(newProject.project_users.trim())|| 0,
      creatorId:Number( newProject.creatorId.trim())  || 0,
      status:Boolean(newProject.status.trim() )  || 0,
      // updatedAt:Date(newProject.updatedAt.trim()) || 0,
      deletedBy: newProject.deletedBy.trim() || 'Unknown', // בדוק שלא ריק
      // createdAt:Date(newProject.createdAt.trim()) || 0,
      updatedBy: newProject.updatedBy.trim() || 'Unknown', // בדוק שלא ריק
      updatedAt: new Date().toISOString(), // עובר לפורמט תקני של תאריך
      createdAt: new Date().toISOString(),

      
    };

    if (
      !projectData.project_name &&
      !projectData.status &&
      !projectData.creatorId &&
      !projectData.deletedBy&&
      !projectData.updatedBy 
    ) {
      alert('Please fill all the required fields with valid data.');
      return;
    }
    

    try {
      await createproject(projectData);
      alert('Project added successfully');
      onClose();
    } catch (error) {
      console.error('Error adding project:', error.response?.data || error.message);
      alert(error.message || "An error occurred.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">×</button>
        <h2>Add New Project</h2>

 

        <div className="form-group">
          <label>Project Name:</label>
          <input
            name="project_name"
            placeholder="79th street - 38 Units Apartment Building"
            value={newProject.project_name}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Address:</label>
          <input
            name="address"
            placeholder="Enter Address"
            value={newProject.address}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Lot Area:</label>
          <input
            name="lotArea"
            placeholder="Enter Lot Area"
            value={newProject.lotArea}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        <div className="form-group">
          <label>Gross Built Up Area:</label>
          <input
            name="gross_building_up_area"
            placeholder="Enter Gross Built Up Area"
            value={newProject.gross_building_up_area}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        <div className="form-group">
          <label>Rentable Area - Residential:</label>
          <input
            name="rentable_area_residential"
            placeholder="Enter Rentable Area - Residential"
            value={newProject.rentable_area_residential}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        <div className="form-group">
          <label>Rentable Area - Commercial:</label>
          <input
            name="rentable_area_commercial"
            placeholder="Enter Rentable Area - Commercial"
            value={newProject.rentable_area_commercial}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        {/* <div className="form-group">
          <label>Total Rentable Area:</label>
          <input
            name="total_rentable_area"
            placeholder="Enter Total Rentable Area"
            value={newProject.total_rentable_area}
            onChange={handleChange}
            className="modal-input"
            type="number" 
            required
          />
        </div> */}

        <div className="form-group">
          <label>Number of Floors:</label>
          <input
            name="number_of_floors"
            placeholder="Enter Number of Floors"
            value={newProject.number_of_floors}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        {/* שדה העלאת תמונה */}
        <div className="form-group">
          <label>image_thumbnail:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            className="modal-input"
          />
          {previewImage && (
            <div className="image-preview">
              <img src={previewImage} alt="Preview" className="preview-thumbnail" />
            </div>
          )}
        </div>


        <div className="form-group">
          <label>SOW Count Completed:</label>
          <input
            name="sow_count_completed"
            placeholder="Enter SOW Count Completed"
            value={newProject.sow_count_completed}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        <div className="form-group">
          <label>SOW Count Pending:</label>
          <input
            name="sow_count_pending"
            placeholder="Enter SOW Count Pending"
            value={newProject.sow_count_pending}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        <div className="form-group">
          <label>Project Users (comma-separated):</label>
          <input
            name="project_users"
            placeholder="Enter Project Users"
            value={newProject.project_users}
            onChange={handleChange}
            className="modal-input"
            type="number" 
          />
        </div>

        <div className="form-group">
          <label>creatorId:</label>
          <input
            name="creatorId"
            placeholder="Enter creatorId"
            value={newProject.creatorId}
            onChange={handleChange}
            className="modal-input"
            type="number" 
            required
          />
        </div>
        <div className="form-group">
          <label>status:</label>
          <input
            name="status"
            placeholder="Enter status"
            value={newProject.status}
            onChange={handleChange}
            className="modal-input"
            type='boolean'
            required
            
          />
        </div>
        <div className="form-group">
          <label>deletedAt:</label>
          <input
            name="deletedAt"
            placeholder="Enter deletedAt"
            value={newProject.deletedAt}
            onChange={handleChange}
            className="modal-input"
            type="date"
          />
        </div>
        <div className="form-group">
          <label>Deleted By:</label>
          <input
            name="deletedBy"
            placeholder="Enter Deleted By"
            value={newProject.deletedBy}
            onChange={handleChange}
            className="modal-input"
            type="text"
            required
          />
        </div>
        <div className="form-group">
          <label>createdAt:</label>
          <input
            name="createdAt"
            placeholder="Enter createdAt"
            value={newProject.createdAt}
            onChange={handleChange}
            className="modal-input"
            type="date"
          />
        </div>
        <div className="form-group">
          <label>updatedBy:</label>
          <input
            name="updatedBy"
            placeholder="Enter updatedBy"
            value={newProject.updatedBy}
            onChange={handleChange}
            className="modal-input"
            type="text"
            required
          />
        </div>
        <div className="form-group">
          <label>updatedAt:</label>
          <input
            name="updatedAt"
            placeholder="Enter updatedAt"
            value={newProject.updatedAt}
            onChange={handleChange}
            className="modal-input"
            type="date"
          />
        </div>
        

        <div
          className="file-upload-container"
          onClick={() => document.getElementById('file-input').click()}
        >
          <img
            src={require('../../IMG/pic9.jpg')}
            alt="Upload Icon"
            className="file-upload-icon"
          />
          {/* <div className="file-upload-text1">Media Upload</div>
          <div className="file-upload-text2">Drag and Drop/select image</div>
          <input
            type="file"
            id="file-input"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          /> */}
        </div>

        <div className="button-container">
          
          <button onClick={onClose} className="cancel-button">Cancel</button>
          <button onClick={handleAddProject} className="add-button">Save</button>
        </div>
      </div>
    </div>
  );
}

export default ProjectModal;
