import React from 'react';
import '../../assets/css/userPage/userList.css';
import SowTable from '../Common/SowTable';
import { MdOutlineSettings } from "react-icons/md";
import PropTypes from "prop-types";
import { FaRegTrashAlt } from "react-icons/fa";

function UserList({ users, onSettingsClick, onDeleteClick }) {
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Role",
      dataIndex: "role_type",
      key: "role_type",
      width: "15%",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (value) => <>{value ? new Date(value).toLocaleDateString() : 'N/A'}</>
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (value) => (
        <span className={`status ${value ? 'active' : 'inactive'}`}>
          {value ? 'Active' : 'Inactive'}
        </span>
      )
    },
    {
      title: "Actions",
      key: "actions",
      width: "60px",
      render: (_, row) => (
        <div className="action-btns">
          <FaRegTrashAlt 
            className="cursor-pointer mr-2" 
            onClick={() => onDeleteClick(row.id)}
            title="Delete"
            size={16}
          />
          <MdOutlineSettings 
            className="cursor-pointer" 
            onClick={() => onSettingsClick(row)}
            title="View"
            size={18}
          />
        </div>       
      ),
    },
  ];

  return (
    // <section className="user-list-container">
    //   <h3 className="user-list-title">Users</h3>
    //   <div className="user-table-wrapper">
    //     <table className="user-table">
    //       <thead>
    //         <tr>
    //           <th>Email address</th>
    //           <th>Role</th>
    //           <th>Date Added</th>
    //           <th>Status</th>
    //           <th>Settings</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {users.map((user, index) => (
    //           <tr key={index}>
    //             <td>{user.email}</td>
    //             <td>{user.role_type || 'N/A'}</td> 
    //             <td>{user.createdAt ? new Date(user.createdAt).toLocaleDateString() : 'N/A'}</td>
    //             <td>
    //               <span className={`status ${user.is_active ? 'active' : 'inactive'}`}>
    //                 {user.is_active ? 'Active' : 'Inactive'}
    //               </span>
    //             </td>
    //             <td><button 
    //                 className="settings-button" 
    //                 onClick={() => onSettingsClick(user)}
    //                 title="Settings">⚙️</button></td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </table>
    //   </div>
    // </section>
    <SowTable
      header="Users"
      rowKey="id"
      data={users}
      columns={columns}
      bordered
    />
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      role_type: PropTypes.string,
      createdAt: PropTypes.string,
      is_active: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default UserList;
