import React from "react";

import '../../assets/css/Sow/createSowContractPage.css'
import Header from "../../components/userPage/header";
import SideMenu from "../../components/userPage/sideMenu";
import FormComponent from "../../components/Sow/sowForm";
import InputFieldsComponent from "../../components/Sow/createInputForSow";

const CreateSowContractPage = () => {
    return (
        <div className="main-page-container">
            <SideMenu />
            <div className="main-content">
                <Header
                    title="SOW 1"
                    addButtonLabel="edit"
                    showAddButton={true}
                />
                <div className="content">
                    <FormComponent />
                    <InputFieldsComponent />
                </div>
            </div>
        </div>
    );
};

export default CreateSowContractPage;