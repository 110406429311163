import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/project/projectGrid.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import SowAlert from "../Common/SowAlert";

function ProjectGrid({ projects, handleDeleteProject, handleView, onSettingsClick }) {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const cardRef = useRef(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setActiveDropdown(null); 
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={cardRef}>
      <h3 className="user-list-title">Project</h3>
      {projects.length > 0 ? (
        <div className="sow-cards-grid">
          {projects.map((project, index) => (
            <div className="sow-card" key={index}>
              <div className="icon-container">
                <i onClick={() => toggleDropdown(index)} className="fas fa-ellipsis-v menu-icon"></i>
              </div>
              {activeDropdown === index && (
                <div className="dropdown-menu">
                  <ul>
                    <li onClick={() => onSettingsClick(project)}>Setting</li>
                    <li onClick={() => handleDeleteProject(project)}>Delete</li>
                  </ul>
                </div>
              )}
              <div className="image-overlay">
                <img src={project.image_thumbnail} alt={project.project_name} className="sow-image" />
              </div>
              <div className="sow-details">
                <span className="sow-title">{project.project_name || 'N/A'}</span>
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{ width: `${(index + 1) * 10}%` }}
                  ></div>
                </div>
                <span className="progress-percentage">{(index + 1) * 10}%</span>
                <button onClick={() => handleView(project.id)} className="view-button">VIEW</button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <SowAlert 
          type="warning"
          message="No records to display"
        />
      )}
    </div>
  );
}
export default ProjectGrid;
