import React from 'react';
import '../../assets/css/Company/company.css';
import SowTable from "../Common/SowTable";
import PropTypes from "prop-types";

function CompaniesList({ companies }) {
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "15%"
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
    },
    {
      title: "Contact Person",
      key: "contact_person",
      dataIndex: "contact_person",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "project_name",
      width: "60px",
    },
  ]
  return (
    <SowTable
      header="Companies"
      rowKey="id"
      data={companies}
      columns={columns}
      bordered
    />
  );
}

CompaniesList.prototype = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string,
      contact_person: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      project_name: PropTypes.string,
    })
  ).isRequired,
};

export default CompaniesList;
