import apiClient from '../login/apiClient';



export const createproject = async (projectData) => {
  const token = localStorage.getItem('accessToken'); 

  console.log('Sending request with token:', token); 

  if (!token) {
    alert('Access token is missing.');
    return; 
  }

  try {
    const response = await apiClient.post('/api/projects', projectData, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    console.log('project created successfully:', response.data); 
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to create user');
  }
};
export const searchProjects = async (searchTerm) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.get(`/api/projects/search?project_name=${searchTerm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Projects fetched successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to fetch projects');
  }
};

export const deleteProject = async (id) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.delete(`/api/projects/id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Project deleted successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to delete project');
  }
};

export const getAllProject = async (params) => {
  try {
    const response = await apiClient.get(`/api/projects?${params}`);

    console.log('Project fetch successfully:', response.data);
    return response.data;

  } catch (error) {
    console.error('Error fetch projects:', error.message);
    alert('Failed to fetch projects. Please try again later.');
    return;
  }
};

export const updateProject = async (id, updatedData) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/projects/${id}`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update project');
  }
};

const handleError = (error, defaultMessage) => {
  if (error.response) {
    console.error('Server Error:', error.response.status, error.response.data);
    alert(`Server Error: ${error.response.data.message || defaultMessage}`);
    throw new Error(error.response.data.message || defaultMessage);

  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please check your network connection.');
    throw new Error('No response from the server');

  } else {
    console.error('Error in setting up request:', error.message);
    alert(`Error in setting up request: ${error.message}`);
    throw new Error(error.message);
  }
};