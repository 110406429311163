import apiClient from './apiClient';
import { refreshToken } from './refreshToken';  // פונקציה לחידוש refreshToken

// פונקציה לביצוע login
export const login = async (email, password) => {
  try {
    const response = await apiClient.post(`/api/auth/login`, {
      email: email,
      password: password
    });

    const { accessToken, refreshToken, userdetails } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('userDetails', JSON.stringify(userdetails));

    if (userdetails?.id) {
      localStorage.setItem('userId', userdetails.id.toString()); 
    } else {
      console.warn('User ID is missing in login response.');
    }

    console.log('Login successful. Tokens saved in Local Storage.');
    return response.data;  // מחזיר את הנתונים מהתשובה
  } catch (error) {
    if (error.response?.status === 403 && error.response?.data?.locked) {
      throw new Error('Account is locked. Try again in 30 minutes.');
    } else {
      throw new Error(error.response?.data?.message || 'Login failed');
    }
  }
};

// פונקציה לקריאה לנתיב מוגן עם בדיקת תוקף accessToken
export const fetchProtectedData = async () => {
  try {
    let token = localStorage.getItem('accessToken');  // מקבלת את ה-token מ-localStorage

    if (!token) {
      throw new Error('No access token found. Please login again.');
    }

    // שליחת הבקשה עם ה-access token הנוכחי
    const response = await apiClient.get(`/api/protected-route`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;  // מחזיר את הנתונים מהתשובה
  } catch (error) {
    // אם התשובה היא 401, ננסה לחדש את ה-token באמצעות refreshToken
    if (error.response?.status === 401) {
      console.log('Access token expired. Attempting to refresh the token.');

      try {
        // חידוש ה-access token
        const newAccessToken = await refreshToken();
        console.log('New access token obtained:', newAccessToken);

        // שליחה מחדש של הבקשה עם ה-access token החדש
        const response = await apiClient.get(`/api/protected-route`, {
          headers: {
            'Authorization': `Bearer ${newAccessToken}`,
          },
        });

        return response.data;  // מחזיר את הנתונים מהתשובה
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        throw new Error('Failed to refresh token. Please login again.');
      }
    } else {
      throw new Error('Failed to fetch protected data.');
    }
  }
};
