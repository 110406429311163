import React from 'react';
import '../../assets/css/Department/departmentsList.css';
import SowTable from '../Common/SowTable';

function DepartmentsList({ departments }) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      width: "60px",
      // render: (_, row) => (
      //   <div className="action-btns">
      //     <FaRegTrashAlt 
      //       className="cursor-pointer mr-2" 
      //       onClick={() => handleDeleteProject(row)}
      //       title="Delete"
      //       size={16}
      //     />
      //     <MdOutlineSettings 
      //       className="cursor-pointer" 
      //       onClick={() => handleView(row.id)}
      //       title="View"
      //       size={18}
      //     />
      //   </div>       
      // ),
    },
  ];

  return (
    // <section className="department-list-container">
    //   <h3 className="department-list-title">Departments</h3>
    //   <div className="department-table-wrapper">
    //     <table className="department-table">
    //       <thead>
    //         <tr>
    //           <th>Name</th>
    //           <th>Description</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {departments.map((department, index) => (
    //           <tr key={index}>
    //             <td>{department.name || 'N/A'}</td>
    //             <td>{department.description || 'N/A'}</td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </table>
    //   </div>
    // </section>
    <SowTable
      header="Departments"
      rowKey="id"
      data={departments}
      columns={columns}
      bordered
    />
  );
}

export default DepartmentsList;
