import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { updateUser } from "../../api/userPage/user"; 
import "../../assets/css/Common/settingsModal.css";
import { updateProject } from "../../api/project/project";

const SettingsModal = ({ isOpen, onClose, entity, entityType, fields, onUpdate }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (entity) {
      setFormData({ ...entity });
    }
  }, [entity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    switch (entityType) {
      case 'User':
        handleUpdateUser();
        break;
      case 'Project':
        handleUpdateProject();
        break;
      default:
        break;
    }
  };

  const handleUpdateUser = async () => {
    try {
      if (onUpdate) {
        await updateUser(entity.id, formData); 
        await onUpdate(formData); 
      }
      alert(`${entityType} updated successfully!`);
      onClose();
    } catch (error) {
      console.error(`Failed to update ${entityType}:`, error);
      alert(`Failed to update ${entityType}. Please try again.`);
    }
  }

  const handleUpdateProject = async () => {
    try {
      if (onUpdate) {
        await updateProject(entity.id, formData); 
        await onUpdate(formData); 
      }
      alert(`${entityType} updated successfully!`);
      onClose();
    } catch (error) {
      console.error(`Failed to update ${entityType}:`, error);
      alert(`Failed to update ${entityType}. Please try again.`);
    }
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Edit {entityType}</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {fields.map((field) => (
            <div key={field.name} className="form-group">
              <label>{field.label}</label>
              {field.type === "select" ? (
                <select
                  name={field.name}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type || "text"}
                  name={field.name}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  readOnly={field.readOnly}
                  className={field.readOnly ? "read-only-input" : ""}
                />
              )}
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button className="update-button" onClick={handleSubmit}>
            Update
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

SettingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.oneOf(['User', 'Project']).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
      readOnly: PropTypes.bool,
    })
  ).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default SettingsModal;
