
import apiClient from "../login/apiClient";


export const getUserDetails = async (userId) => {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    alert("Access token is missing.");
    return null;
  }

  try {
    const response = await apiClient.get(`/api/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("User details fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Failed to fetch user details:",
      error.response?.data || error.message
    );
    alert(error.response?.data?.message || "Failed to fetch user details.");
    return null;
  }
};


export const updateUserDetails = async (userId, updatedData) => {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    alert("Access token is missing.");
    return null;
  }

  try {
    const filteredData = Object.fromEntries(
      Object.entries(updatedData).filter(([_, value]) => value !== undefined && value !== null)
    );

    console.log("Filtered data sent to server:", filteredData);

    const response = await apiClient.put(`/api/users/${userId}`, filteredData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("Response from server:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in updateUserDetails:", error.response?.data || error.message);
    alert(error.response?.data?.message || "Failed to update user details.");
    return null;
  }
};
