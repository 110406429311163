import React, { useState } from 'react';
import { createCompany } from '../../api/Companies/company'; 
import '../../assets/css/userPage/userModal.css';

function CompanyModal({ isOpen, onClose }) {
  const [newCompany, setNewCompany] = useState({
    name: '',
    address: '',
    contact_person: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCompany((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddCompany = async () => {
    const companyData = {
      name: newCompany.name.trim(),
      address: newCompany.address.trim(),
      contact_person: newCompany.contact_person.trim(),
      email: newCompany.email.trim(),
      phone: newCompany.phone.trim(),
    };

    if (!companyData.name || !companyData.address || !companyData.email) {
      alert('Please fill all the required fields.');
      return;
    }

    try {
      await createCompany(companyData); 
      alert('Company added successfully');
      onClose();
    } catch (error) {
      console.error('Error adding company:', error.response?.data || error.message);
      alert(error.message || 'An error occurred while adding the company.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">×</button>
        <h2>Add New Company</h2>

        <div className="form-group">
          <label>Name</label>
          <input
            name="name"
            placeholder="Enter Company Name"
            value={newCompany.name}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Address</label>
          <input
            name="address"
            placeholder="Enter Address"
            value={newCompany.address}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Contact Person</label>
          <input
            name="contact_person"
            placeholder="Enter Contact Person"
            value={newCompany.contact_person}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            name="email"
            placeholder="Enter Email"
            type="email"
            value={newCompany.email}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Phone</label>
          <input
            name="phone"
            placeholder="Enter Phone Number"
            value={newCompany.phone}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <button onClick={handleAddCompany} className="add-button">
          Add Company
        </button>
      </div>
    </div>
  );
}

export default CompanyModal;
