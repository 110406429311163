import React, { useState } from 'react';
import { login } from '../../api/login/auth'; 
import { FaEnvelope } from 'react-icons/fa';
import { GoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import ShowPasswordToggle from './ShowPasswordToggle';

export const LoginForm = ({ handleGoogleSuccess, handleGoogleFailure }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false); 
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Sending:', { email, password });  

    if (!validateEmail(email)) {
      setErrorMessage('Invalid email format');
      return; 
    }

    if (password.trim() === '') {
      setErrorMessage('Password cannot be empty');
      return; 
    }

    try {
      const data = await login(email, password); 
      console.log('Login success:', data);

      navigate('/dashboard');
    } catch (error) {
      if (error.message.includes('locked')) {
        setErrorMessage("Account is locked. Try again in 30 minutes.");
        setDisabled(true); 
      } else if (error.message.includes('Invalid email or password')) {
        setErrorMessage("Invalid email or password.");
      } else {
        setErrorMessage(error.message); 
      }
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="input-box">
        <FaEnvelope className="icon" />
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={disabled} 
        />
      </div>

      <ShowPasswordToggle password={password} setPassword={setPassword} />

      <button type="submit" className="login-btn" disabled={disabled}>
        Login
      </button>
 
       <a href="#" className="forgot-password" onClick={() => navigate('/forgot-password')}>
        Forgot Password
      </a> 
      <div className="social-login">
        <GoogleLogin
          // onSuccess={handleGoogleSuccess}
          // onError={handleGoogleFailure}
          render={(renderProps) => (
            <button
              type="button"
              className="social-btn google"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled || disabled} 
            >
              <FcGoogle size={24} style={{ marginRight: '10px', borderRadius: '15px' }} /> Sign In with Google
            </button>
          )}
        />
      </div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </form>
  );
};
