import PropTypes from "prop-types";
import "../../assets/css/Common/sowTable.css";

const SowTable = ({ 
  columns,
  data,
  rowKey,
  onRow,
  bordered,
  header,
  isLoading
}) => {
  return (
    <div className={`custom-table-container ${bordered ? 'bordered' : ''}`}>
      {header && <div className="custom-table-header">{header}</div>}
      <div className="custom-table-wrapper">
        {isLoading && <div className="custom-table-overlay">Loading...</div>}
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th 
                  key={column.key}
                  style={{ ...column.style, width: column.width || 'auto' }}
                  title={typeof column.title === "string" ? column.title : undefined}
                >
                  <div >{column.title}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? data.map((row, idx) => (
              <tr
                key={row[rowKey]}
                onClick={onRow ? () => onRow(row) : undefined}
              >
                {columns.map((column) => (
                  <td key={column.key}>
                    {column.render 
                      ? column.render(row[column.dataIndex], row) 
                      : row[column.dataIndex]}
                  </td>
                ))}
              </tr>
            )) : (
              <tr>
                <td colSpan={columns.length}>No records to display.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
};

SowTable.prototype = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
      style: PropTypes.object,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowKey: PropTypes.string.isRequired,
  onRow: PropTypes.func,
  bordered: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isLoading: PropTypes.bool
}

export default SowTable;
