import React, { useState } from "react";
import '../../assets/css/Sow/addNewSowTemplatePage.css';
import Header from "../../components/userPage/header";
import SideMenu from "../../components/userPage/sideMenu";
import TemplateViewer from "../../components/Sow/newSowTemplateViewer";
import DragAndDrop from "../../components/Sow/dragAndDropComponent";


const ReleventChangesSowTemplate = () => {
  const [templateItems, setTemplateItems] = useState([]);

  const handleDropItem = (item) => {
    setTemplateItems((prevItems) => [...prevItems, item]);
  };

  return (
    <div className="sow-page-layout">
      <SideMenu />
      <div className="main-content">
        <Header
          title="SOW Templates"
          addButtonLabel="select sow section"
          showAddButton={true}
          showRightIcons={true}
         
        />
        <main className="content-container">
          <DragAndDrop onDropItem={handleDropItem} />
          <TemplateViewer
            templateItems={templateItems}
            onDropItem={handleDropItem}
          />
        </main>
      </div>
    </div>
  );
};

export default ReleventChangesSowTemplate;
