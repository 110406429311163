import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/login/homePage';
import { LoginPage } from './pages/login/loginPage';
// import { ForgotPassword } from './pages/login/forgatPasswordPage';
import ResetPasswordPage from './pages/login/resetPasswordPage';
import ProtectedPage from './pages/login/protectedPage';  
import UserPage from './pages/userPage/userListPage';
import Profile from './pages/Profile/profile'
import Department from './pages/Departments/departments'
import Company from './pages/Company/companies'
import Trades from './pages/Trades/trades'
import Project from './pages/Project/project';
import Dashboard from './pages/Dashboard/dashboard';
import ForgotPassword from './pages/login/forgotPasswordPage';

import TemplatesSowPage from './pages/Sow/TemplatesSowPage';
import CreateSowContractPage from './pages/Sow/createContractSowPage';
import SowList from './components/Sow/SowList';
import NewSowTemplatesPage from './pages/Sow/newSowTemplatePage';
import ReleventChangesSowTemplate from './pages/Sow/ReleventChangesSowTemplatePage';









const isAuthenticated = () => {
  const token = localStorage.getItem('accessToken');
  return !!token;  
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/user-list" element={<UserPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/department" element={<Department />} />
        <Route path="/company" element={<Company />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/trades" element={<Trades />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetToken" element={<ResetPasswordPage />} /> 
        {/* <Route path="/project/:id" element={<Sow/>} /> */}
        <Route path="/sow-templates" element={<TemplatesSowPage />} />
        <Route path="/sow-contract" element={<CreateSowContractPage/>} />
        <Route path="/sow-list" element={<SowList/>} />
        <Route path="/new-sow-template" element={<NewSowTemplatesPage/>} />
        <Route path="/relavent -changes-sow-template" element={<ReleventChangesSowTemplate/>} />
        

       
   

        
        <Route
          path="/protectedPage"
          element={isAuthenticated() ? <ProtectedPage /> : <Navigate to="/user-list" />}
        />

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};



export default App;
