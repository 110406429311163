import React, { useState, useEffect } from "react";
import "../../assets/css/userPage/userPage.css";
import Pagination from "../../components/userPage/pagination";
import Header from "../../components/userPage/header";
import UserList from "../../components/userPage/userList";
import SideMenu from "../../components/userPage/sideMenu";
import UserModal from "../../components/userPage/userModal";
import SettingsModal from "../../components/Common/SettingsModal";
import DeleteDialog from "../../components/Common/DeleteDialog";
import { searchUsers, deleteUser } from "../../api/userPage/user";

function UserPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await searchUsers(searchTerm, currentPage);
        setUsers(data.data);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [searchTerm, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOpenSettings = (user) => {
    setSelectedUser(user);
    setIsSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setSelectedUser(null);
    setIsSettingsOpen(false);
  };

  const handleOpenDeleteDialog = (userId) => {
    setUserToDelete(userId);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setUserToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDeleteUser = async () => {
    try {
      await deleteUser(userToDelete);
      alert("User deleted successfully.");
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete));
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete user. Please try again.");
    } finally {
      handleCloseDeleteDialog();
    }
  };

  return (
    <div className="user-page-layout">
      <SideMenu />
      <div className="content-wrapper">
        <Header
          title="Users"
          addButtonLabel="Add New User"
          searchPlaceholder="Find User"
          searchValue={searchTerm}
          onSearchChange={handleSearchChange}
          ModalComponent={UserModal}
        />
        <UserList
          users={users}
          onSettingsClick={handleOpenSettings}
          onDeleteClick={handleOpenDeleteDialog}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        {isSettingsOpen && selectedUser && (
          <SettingsModal
            isOpen={isSettingsOpen}
            onClose={handleCloseSettings}
            entity={selectedUser}
            entityType="User"
            fields={[
              { name: "email", label: "Email Address", type: "email", readOnly: true },
              {
                name: "role_id",
                label: "Role ID",
                type: "select",
                options: [
                  { value: "1", label: "Admin" },
                  { value: "2", label: "Member" },
                  { value: "3", label: "User" },
                ],
              },
              {
                name: "role_type",
                label: "Role Type",
                type: "select",
                options: [
                  { value: "Frontend Developer", label: "Frontend Developer" },
                  { value: "Backend Developer", label: "Backend Developer" },
                  { value: "UX/UI Designer", label: "UX/UI Designer" },
                  { value: "QA Engineer", label: "QA Engineer" },
                ],
              },
              {
                name: "is_active",
                label: "Status",
                type: "select",
                options: [
                  { value: "1", label: "Active" },
                  { value: "0", label: "Inactive" },
                ],
              },
            ]}
            onUpdate={() => setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user.id === selectedUser.id
                  ? { ...user, ...selectedUser }
                  : user
              )
            )}
          />
        )}
        {isDeleteDialogOpen && (
          <DeleteDialog
            id="delete-user-dialog"
            title="Delete User"
            message="Are you sure you want to delete this user?"
            onConfirm={handleConfirmDeleteUser}
            onCancel={handleCloseDeleteDialog}
          />
        )}
      </div>
    </div>
  );
}

export default UserPage;
