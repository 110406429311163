import React, { useState, useEffect } from "react";
import "../../assets/css/profile/profile.css";
import Header from "../../components/userPage/header";
import SideMenu from "../../components/userPage/sideMenu";
import { getUserDetails, updateUserDetails } from "../../api/profile/profile";

function ProfilePage() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    userName: "",
    password: "********",
  });

  const [originalData, setOriginalData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [editField, setEditField] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      alert("User ID is missing.");
      setLoading(false);
      return;
    }

    const fetchUserDetails = async () => {
      const response = await getUserDetails(userId);
      if (response && response.data) {
        const userDetails = response.data;
        const fullName = `${userDetails.name || ""} ${userDetails.last_name || ""}`.trim();
        const updatedFormData = {
          fullName,
          email: userDetails.email || "",
          mobileNumber: userDetails.UserDetail?.cell_phone || "N/A",
          userName: userDetails.name || "",
          password: userDetails.password || "********",
        };
        setFormData(updatedFormData);
        setOriginalData(updatedFormData);
      }
      setLoading(false);
    };

    fetchUserDetails();
  }, []);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditClick = (field) => {
    setEditField(field);
  };

  const handleSave = async () => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      alert("User ID is missing.");
      return;
    }

    try {
      const [firstName, lastName] = formData.fullName.split(" ");
      const updatedData = {
        name: firstName || "",
        last_name: lastName || "",
        email: formData.email,
        UserDetail: {
          cell_phone: formData.mobileNumber,
        },
      };

      const response = await updateUserDetails(userId, updatedData);
      if (response) {
        alert("Profile updated successfully!");
        setOriginalData(formData);
        setEditField(null);
      } else {
        throw new Error("Failed to update user details.");
      }
    } catch (error) {
      console.error("Failed to update profile:", error);
      alert("Failed to update profile. Please try again.");
    }
  };

  const handleCancel = () => {
    setFormData(originalData);
    setEditField(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page-layout">
      <SideMenu />
      <div className="profile-main-content">
        <Header title="Profile" showSearch={false} showAddButton={false} />
        <div className="profile-container">
          <div className="profile-header">
            <h2>Profile</h2>
          </div>
          <div className="profile-details-container">
            <div className="profile-avatar-section">
              <img
                src="https://via.placeholder.com/100"
                alt="Profile"
                className="profile-avatar"
              />
              <div className="profile-info">
                <h3>{formData.fullName}</h3>
                <p>{formData.email}</p>
              </div>
            </div>
          </div>
          <div className="profile-form">
            <div className="form-row">
              <div className="form-group">
                <label>Full Name</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={formData.fullName}
                    readOnly={editField !== "fullName"}
                    onChange={(e) => handleInputChange("fullName", e.target.value)}
                  />
                  <i
                    className="fas fa-edit input-icon"
                    onClick={() => handleEditClick("fullName")}
                  ></i>
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <div className="input-wrapper">
                  <input
                    type="email"
                    value={formData.email}
                    readOnly={editField !== "email"}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  <i
                    className="fas fa-edit input-icon"
                    onClick={() => handleEditClick("email")}
                  ></i>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Mobile Number</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={formData.mobileNumber}
                    readOnly={editField !== "mobileNumber"}
                    onChange={(e) => handleInputChange("mobileNumber", e.target.value)}
                  />
                  <i
                    className="fas fa-edit input-icon"
                    onClick={() => handleEditClick("mobileNumber")}
                  ></i>
                </div>
              </div>
              <div className="form-group">
                <label>User Name</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={formData.userName}
                    readOnly={editField !== "userName"}
                    onChange={(e) => handleInputChange("userName", e.target.value)}
                  />
                  <i
                    className="fas fa-edit input-icon"
                    onClick={() => handleEditClick("userName")}
                  ></i>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group password-group">
                <label>Password</label>
                <div className="input-wrapper">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    readOnly={editField !== "password"}
                    onChange={(e) => handleInputChange("password", e.target.value)}
                  />
                  <i
                    className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} input-icon`}
                    onClick={() => setShowPassword((prev) => !prev)}
                  ></i>
                </div>
              </div>
            </div>

            <div className="form-actions">
              <button className="cancel-button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="save-button" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
