import React, { useState } from 'react';
import '../../assets/css/userPage/userList.css';
import DeleteDialog from '../Common/DeleteDialog';
import { FaRegTrashAlt } from "react-icons/fa"; // ייבוא האייקון
import SowTable from '../Common/SowTable';
import { MdOutlineSettings } from "react-icons/md";

function RawItemsList({ rawItems, onDelete }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // לניהול הפריט שנבחר למחיקה

  const handleDeleteClick = (item) => {
    setSelectedItem(item); // הגדרת הפריט שנבחר למחיקה
    setIsDialogOpen(true); // פתיחת הדיאלוג
  };

  const handleDeleteConfirm = () => {
    if (selectedItem) {
      onDelete(selectedItem.id); // קריאה לפונקציית המחיקה
      setIsDialogOpen(false); // סגירת הדיאלוג
      setSelectedItem(null); // ניקוי הבחירה
    }
  };

  const handleDeleteCancel = () => {
    setIsDialogOpen(false);
    setSelectedItem(null);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "item_name",
      key: "item_name",
      width: "10%",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Description",
      dataIndex: "item_description",
      key: "item_description",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "Availability",
      dataIndex: "is_available",
      key: "is_available",
      width: "10%",
      render: (value) => <>{value === 1 ? 'Yes' : 'No'}</>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (value) => <>{value === 1 ? 'Active' : 'Inactive'}</>
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "10%",
      render: (value, row) => (
        <>
          {value ? <img src={value} alt={row.item_name} style={{ width: '50px' }} /> : 'N/A'} 
        </>
      )
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) => <>{value === 1 ? 'Active' : 'Inactive'}</>
    },
    {
      title: "Actions",
      key: "actions",
      width: "60px",
      render: (_, row) => (
        <div className="action-btns">
          <FaRegTrashAlt 
            className="cursor-pointer mr-2" 
            onClick={() => handleDeleteClick(row)}
            title="Delete"
            size={16}
          />
          {/* <MdOutlineSettings 
            className="cursor-pointer" 
            onClick={() => handleView(row.id)}
            title="View"
            size={18}
          /> */}
        </div>       
      ),
    },
  ];

  return (
    // <section className="user-list-container">
    //   <h3 className="user-list-title">Raw Items</h3>
    //   <div className="user-table-wrapper">
    //     <table className="user-table">
    //       <thead>
    //         <tr>
    //           <th>Item Name</th>
    //           <th>Description</th>
    //           <th>Is Available</th>
    //           <th>Status</th>
    //           <th>Image</th>
    //           <th>Updated At</th>
    //           <th>Date Created</th>
    //           <th>Actions</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {rawItems.length > 0 ? (
    //           rawItems.map((item, index) => (
    //             <tr key={index}>
    //               <td>{item.item_name || 'N/A'}</td>
    //               <td>{item.item_description || 'N/A'}</td>
    //               <td>{item.is_available === 1 ? 'Yes' : 'No'}</td>
    //               <td>{item.status === 1 ? 'Active' : 'Inactive'}</td>
    //               <td>{item.image ? <img src={item.image} alt={item.item_name} style={{ width: '50px' }} /> : 'N/A'}</td>
    //               <td>{item.updated_at || 'N/A'}</td>
    //               <td>
    //                 <button
    //                   onClick={() => handleDeleteClick(item)}
    //                   // className="delete-button"
    //                   title="Delete"
    //                   style={{ background: 'none', border: 'none', cursor: 'pointer' }}
    //                 >
    //                   <FaRegTrashAlt style={{ color: 'black', fontSize: '1.2rem' }} /> 
    //                 </button>
    //               </td>
    //             </tr>
    //           ))
    //         ) : (
    //           <tr>
    //             <td colSpan="9">No raw items available</td>
    //           </tr>
    //         )}
    //       </tbody>
    //     </table>
    //   </div>

    <>
      <SowTable
        header="Raw Items"
        rowKey="id"
        data={rawItems}
        columns={columns}
        bordered
      />
      {isDialogOpen && (
        <DeleteDialog
          id="delete-dialog"
          title="Delete Item"
          message={`Are you sure you want to delete "${selectedItem?.item_name}"? This action cannot be undone.`}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}

export default RawItemsList;
