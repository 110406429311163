import React, { useState } from 'react';

const AddTemplateModal = ({ onClose, onSave, categories }) => {
  const [templateName, setTemplateName] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);
  const [document, setDocument] = useState(null);

  const handleSave = () => {
    const newTemplate = {
      name: templateName,
      category,
      image,
      document,
    };
    onSave(newTemplate); // שמירת התבנית החדשה
    onClose(); // סגירת החלון
  };

  return (
    <div className="modal">
      <h2>Add New SOW Template</h2>
      <label>
        Template Name:
        <input
          type="text"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </label>
      <label>
        Category:
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.name}>
              {cat.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Upload Image:
        <input
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
        />
      </label>
      <label>
        Upload Document:
        <input
          type="file"
          onChange={(e) => setDocument(e.target.files[0])}
        />
      </label>
      <button onClick={handleSave}>Save</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};

export default AddTemplateModal;
