import React, { useEffect, useState } from 'react';
import { fetchProtectedData } from '../../api/login/auth';  // ייבוא הקריאה לנתיב המוגן

const ProtectedPage = () => {
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        console.log('Fetching protected data...');
        const result = await fetchProtectedData();  // קריאה לנתיב המוגן
        console.log('Data fetched:', result);  // הדפסת הנתונים שנשלחו מהשרת
        setData(result);
      } catch (error) {
        console.error('Error fetching protected data:', error);
        setErrorMessage(error.message || 'Failed to fetch protected data');
      }
    };

    getData();
  }, []);

  return (
    <div className="protected-page">
      <h2>Protected Page</h2>
      {errorMessage ? <p>{errorMessage}</p> : <p>{JSON.stringify(data)}</p>}
    </div>
  );
};

export default ProtectedPage;
