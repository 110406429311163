import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineClose, MdOutlineWarning  } from "react-icons/md";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import "../../assets/css/Common/sowAlert.css";

const SowAlert = ({ type, message, closable }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  const typeClasses = {
    success: 'alert-success',
    warning: 'alert-warning',
    primary: 'alert-primary',
    danger: 'alert-danger',
  };

  const alertClass = typeClasses[type] || typeClasses.primary;

  const RenderIcon = () => {
    switch (type) {
      case 'success':
        return <FaCheckCircle size={18} className="alert-success-icon" />;
      case 'warning':
        return <MdOutlineWarning size={18} className="alert-warning-icon" />;
      case 'primary':
        return <FaInfoCircle size={18} className="alert-primary-icon" />;
      default:
        return <IoIosCloseCircle size={18} className="alert-danger-icon" />;
    }
  }

  return (
    <div className={`alert ${alertClass}`} role="alert">
      <div className="alert-content">
        <RenderIcon />
        <div className="alert-message">{message}</div>
      </div>
      {closable && (
        <button
          type="button"
          className="alert-close"
          onClick={() => setIsVisible(false)}
        >
          <MdOutlineClose />
        </button>
      )}
    </div>
  );
};

SowAlert.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'primary', 'danger']),
  message: PropTypes.string.isRequired,
  closable: PropTypes.bool,
};

export default SowAlert;
