import apiClient from '../login/apiClient';

export const createUser = async (userData) => {
  const token = localStorage.getItem('accessToken'); 

  console.log('Sending request with token:', token); 

  if (!token) {
    alert('Access token is missing.');
    return; 
  }

  try {
    const response = await apiClient.post('/api/users', userData, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    console.log('User created successfully:', response.data); 
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to create user');
  }
};

export const searchUsers = async (searchTerm) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.get(`/api/users/search?query=${searchTerm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Users fetched successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to fetch users');
  }
};

export const updateUser = async (userId, updatedData) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/users/${userId}`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update user');
  }
};

export const deleteUser = async (userId) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.delete(`/api/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete user');
  }
};

const handleError = (error, defaultMessage) => {
  if (error.response) {
    console.error('Server Error:', error.response.status, error.response.data);
    alert(`Server Error: ${error.response.data.message || defaultMessage}`);
    throw new Error(error.response.data.message || defaultMessage);

  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please check your network connection.');
    throw new Error('No response from the server');

  } else {
    console.error('Error in setting up request:', error.message);
    alert(`Error in setting up request: ${error.message}`);
    throw new Error(error.message);
  }
};
