

import React, { useState, useEffect, useCallback } from 'react';
import '../../assets/css/Trades/trades.css';
import Pagination from '../../components/userPage/pagination';
import Header from '../../components/userPage/header';
import SideMenu from '../../components/userPage/sideMenu';
import TradesList from '../../components/Trades/tradesList'; 
import RawItemsList from '../../components/Trades/raw_Items_List';
import AddTrade from '../../components/Trades/tradeModal'; 
import AddRawItem from '../../components/Trades/raw_Items_Modal';
import { searchTrade, deleteTrade } from '../../api/Trades/trades'; 
import { deleteRawItem, getAllRawItems, searchRawItems } from '../../api/Trades/raw_Items';
import DeleteDialog from '../../components/Common/DeleteDialog';

function TradesPage() {
  // Trades states
  const [searchTerm, setSearchTerm] = useState('');
  const [trades, setTrades] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Raw Items states
  const [rawItemSearchTerm, setRawItemSearchTerm] = useState('');
  const [rawItems, setRawItems] = useState([]);
  const [rawItemsCurrentPage, setRawItemsCurrentPage] = useState(1);
  const [rawItemsTotalPages, setRawItemsTotalPages] = useState(1);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [selectedRawItem, setSelectedRawItem] = useState(null);
  const [deleteType, setDeleteType] = useState('trade' | 'raw_item');

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Error state
  const [error, setError] = useState(null);

  const fetchTrades = useCallback(async() => {
    try {
      setError(null);
      const response = await searchTrade(searchTerm, currentPage);
      setTrades(response.data || []);
      setTotalPages(response.totalPages || 1);
    } catch (error) {
      console.error('Error fetching trades:', error.message);
      setError('Failed to fetch trades. Please try again later.');
    }
  },[currentPage, searchTerm]);

  // Fetch Trades
  useEffect(() => {
    fetchTrades();
  }, []);

  const fetchRawItems = useCallback(async () => {
    try {
      setError(null);
      let data;
      if (rawItemSearchTerm) {
        data = await searchRawItems(rawItemSearchTerm, rawItemsCurrentPage);
      } else {
        data = await getAllRawItems(rawItemsCurrentPage);
      }
      setRawItems(data.data || []);
      setRawItemsTotalPages(data.totalPages || 1);
    } catch (error) {
      console.error('Error fetching raw items:', error.message);
      setError('Failed to fetch raw items. Please try again later.');
    }
  },[rawItemSearchTerm, rawItemsCurrentPage]);

  // Fetch Raw Items
  useEffect(() => {
    fetchRawItems();
  }, []);

  // Handlers
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleRawItemSearchChange = (e) => {
    setRawItemSearchTerm(e.target.value);
    setRawItemsCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRawItemsPageChange = (page) => {
    setRawItemsCurrentPage(page);
  };


  const handleDeleteRawItem = async (data) => {
    setDeleteType('raw_item');
    setSelectedRawItem(data);
    setShowDeleteConfirm(true);
  };

  const handleDeleteTrade = async (trade) => {
    setDeleteType('trade');
    setSelectedTrade(trade);
    setShowDeleteConfirm(true);
  }

  const onDeleteTrade = async () => {
    try {
      const res = await deleteTrade(selectedTrade?.id); 
      if (res.success) {
        onCancelDelete();
        fetchTrades();
      }
    } catch (e) {
      console.log('Error deleting', e)
    }
  }

  const onDeleteRawItem = async () => {
    try {
      const res = await deleteRawItem(selectedTrade?.id); 
      if (res.success) {
        onCancelDelete();
        fetchRawItems();
      }
    } catch (e) {
      console.log('Error deleting', e)
    }
  }

  const onConfirmDelete = () => {
    (deleteType === 'trade') ? onDeleteTrade() : onDeleteRawItem();
  };

  const onCancelDelete = () => {
    setDeleteType('trade');
    setSelectedTrade(null);
    setSelectedRawItem(null);
    setShowDeleteConfirm(false);
  }
  
  return (
    <div className="trade-page-layout">
      <SideMenu />
      <div className="content-wrapper">
        <Header
          title="Trades"
          addButtonLabel="Add New Trade"
          searchPlaceholder="Find Trades"
          searchValue={searchTerm}
          onSearchChange={handleSearchChange}
          ModalComponent={AddTrade}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <TradesList 
          trades={trades} 
          onDeleteClick={handleDeleteTrade} 
          onSettingsClick={() => {}} 
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />

        <section className="section">
          <Header
            title="Raw Items"
            addButtonLabel="Add New Raw Item"
            searchPlaceholder="Find Items"
            searchValue={rawItemSearchTerm}
            onSearchChange={handleRawItemSearchChange}
            ModalComponent={AddRawItem}
            showRightIcons={false}
          />
          <RawItemsList rawItems={rawItems} onDelete={handleDeleteRawItem}   />
   
          <Pagination
            currentPage={rawItemsCurrentPage}
            totalPages={rawItemsTotalPages}
            onPageChange={handleRawItemsPageChange}
          />
        </section>

        {isModalOpen && (
          <AddTrade
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}

        {showDeleteConfirm && (
          <DeleteDialog 
            id={deleteType === 'trade' ? selectedTrade?.id || '' : selectedRawItem?.id || ''}
            title={`Delete ${deleteType === 'trade' ? 'Trade' : 'Raw Item'}`}
            message={`Are you sure you want to delete ${deleteType === 'trade' ? selectedTrade?.name || '' : selectedRawItem?.name || ''}?`}
            onConfirm={onConfirmDelete}
            onCancel={onCancelDelete}
          />
        )}
      </div>
    </div>
  );
}

export default TradesPage;
