import React from 'react';
import '../../assets/css/Sow/templatesComponents.css'

const TemplatesComponent = () => {
  return (
    
    <div className="templates-grid">
      <div className="template-card active">
        <div className="template-icon">
          <i className="fas fa-bolt"></i>
        </div>
        <p>Electrical SOW</p>
        <div className="card-settings-icon">
          <i className="fas fa-cog"></i>
        </div>
      </div>
      <div className="template-card">
        <div className="template-icon">
          <i className="fas fa-wrench"></i>
        </div>
        <p>Plumbing SOW</p>
        <div className="card-settings-icon">
          <i className="fas fa-cog"></i>
        </div>
      </div>
      {Array.from({ length: 8 }, (_, index) => (
        <div className="template-card" key={index}>
          <div className="template-icon">
            <i className="fas fa-file-alt"></i>
          </div>
          <p>Template {index + 1}</p>
          <div className="card-settings-icon">
            <i className="fas fa-cog"></i>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TemplatesComponent;
