import apiClient from "./apiClient";



// פונקציה שבודקת אם הטוקן תקין
export const verifyResetToken = async (token) => {
  try {
    const response = await apiClient.get(`/api/auth/reset-password/${token}`);
    return response.data; 
  } catch (error) {
    throw new Error(
      error.response?.data?.message || 'Failed to verify reset token'
    );
  }
};

