import React from 'react';

import SideMenu from '../../components/userPage/sideMenu';
import Header from '../../components/userPage/header';
import { useNavigate } from 'react-router-dom';
import TemplatesComponent from '../../components/Sow/templatesSowComponent';
import '../../assets/css/Sow/sowTemplatePage.css';

  const TemplatesSowPage  = () => {
  const navigate = useNavigate(); // שימוש ב-useNavigate לניווט

  // פונקציה שמבצעת ניווט לדף החדש
  const handleAddNewTemplate = () => {
    navigate('/new-sow-template'); // כאן הניווט קורה
  };

  // מודאל מדומה שמפעיל ניווט במקום לפתוח מודאל
  const ModalComponent = ({ isOpen, onClose }) => {
    if (!isOpen) return null; // מודאל לא אמור להיפתח
    handleAddNewTemplate(); // מבצע ניווט במקום פתיחת מודאל
    return null; // מודאל ריק
  };

  return (
    <div className="sow-page-layout">
      <SideMenu />
      <div className="main-content">
        <Header
          title="SOW Templates"
          searchPlaceholder="Search Templates..."
          searchValue=""
          onSearchChange={() => {}}
          addButtonLabel="Add New Template"
          showSearch={true}
          showAddButton={true}
          showRightIcons={true}
          ModalComponent={ModalComponent} // מודאל שמפעיל ניווט
        />
        <div className="sow-templates-container">
          <h2 className="sow-title">SOW Templates</h2>
          <div className="category-tabs">
            <button className="category-tab active">Category 1</button>
            <button className="category-tab">Category 2</button>
            <button className="category-tab">Category 3</button>
            <button className="category-tab">Category 4</button>
            <button className="category-tab">Category 5</button>
          </div>
          <TemplatesComponent/>
        </div>
      </div>
    </div>
  );
};

export default TemplatesSowPage;
