import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkProtectedRoute } from '../../api/login/checkProtectedRout'; // ייבוא הפונקציה מהקובץ החדש
import '../../assets/css/login/layout.css'
import '../../assets/css/login/login.css'

const HomePage = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // שימוש ב-useNavigate לניווט מחדש במידת הצורך

  // פונקציה לבדיקת נתיב מוגן
  const handleCheckProtectedRoute = async () => {
    try {
      const data = await checkProtectedRoute(); // קריאה לפונקציה מהקובץ הנפרד
      setMessage(data.message); // מציג את המידע שהתקבל מהשרת
      setError('');
      console.log('no error');
      // navigate('/protectedPage'); // ניתוב לדף המוגן

    } catch (err) {
      if (err.response?.status === 401) {
        // במידה ויש בעיה עם ה-token (Unauthorized)
        // navigate('/login'); // ניתוב לדף ההתחברות
      } else {
        setError('Error: ' + (err.response?.data?.message || err.message));
        setMessage('');
      }
    }
  };

  return (
    <div>
      <h1>Welcome to the Home Page</h1>
      <button onClick={handleCheckProtectedRoute}>Check Protected Route</button>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default HomePage;
