import React from "react";
import "../../assets/css/project/projectList.css";
import { FaRegTrashAlt } from "react-icons/fa";
import { formatNumber } from "../../utils/formatNuber";
import SowTable from "../Common/SowTable";
import { MdOutlineSettings } from "react-icons/md";

function projectList({ projects, handleDeleteProject, handleView, onSettingsClick }) {
  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "project_name",
      width: "15%",
      render: (value, row) => (
        <div className="cursor-pointer" title="View" onClick={() => handleView(row.id)}>{value || 'N/A'}</div>
      )
    },
    { 
      title: "Address", 
      dataIndex: "address", 
      key: "address",
      render: (value) => <>{value || 'N/A'}</>
    },
    {
      title: "No. of Floors",
      dataIndex: "number_of_floors",
      key: "number_of_floors",
      width: "10%",
      render: (value) => <>{formatNumber(value)}</>
    },
    {
      title: "Gross Build-up Area",
      dataIndex: "",
      key: "",
      width: "10%",
      render: () => <>N/A</>
    },
    {
      title: "Rentable Area",
      dataIndex: "rentable_area_residential",
      key: "rentable_area_residential",
      width: "10%",
      render: (value) => <>{formatNumber(value)}</>
    },
    {
      title: "Rentable Area(sqft.)",
      dataIndex: "rentable_area_commercial",
      key: "rentable_area_commercial",
      width: "10%",
      render: (value) => <>{formatNumber(value)}</>
    },
    {
      title: "Total Rentable",
      dataIndex: "sow_count_pending",
      key: "sow_count_pending",
      render: (value) => <>{formatNumber(value)}</>
    },
    {
      title: "Actions",
      key: "actions",
      width: "60px",
      render: (_, row) => (
        <div className="action-btns">
          <FaRegTrashAlt 
            className="cursor-pointer mr-2" 
            onClick={() => handleDeleteProject(row)}
            title="Delete"
            size={16}
          />
          <MdOutlineSettings 
            className="cursor-pointer" 
            onClick={() => onSettingsClick(row.id)}
            title="Edit Project"
            size={18}
          />
        </div>       
      ),
    },
  ];

  return (
    <SowTable
      header="Project"
      rowKey="id"
      data={projects}
      columns={columns}
      bordered
    />
  );
}

export default projectList;
