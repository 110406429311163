import React, { useState } from 'react';
import '../../assets/css/userPage/userModal.css';
import { createRawItem } from '../../api/Trades/raw_Items';

function AddRawItem({ isOpen, onClose }) {
  const [rawItemData, setRawItemData] = useState({
    item_name: '',
    item_description: '',
    is_available: '1',
    status: '1',
    image: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRawItemData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddRawItem = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      alert('User ID is missing.');
      return;
    }

    const rawItemPayload = {
      item_name: rawItemData.item_name.trim(),
      item_description: rawItemData.item_description.trim() || null,
      is_available: Number(rawItemData.is_available),
      status: Number(rawItemData.status),
      image: rawItemData.image.trim() || null,
      created_by: Number(userId), 
    };

    if (!rawItemPayload.item_name) {
      alert('Please fill all the required fields.');
      return;
    }

    try {
      await createRawItem(rawItemPayload);
      alert('Raw Item added successfully!');
      onClose();
    } catch (error) {
      console.error('Error adding raw item:', error.response?.data || error.message);
      alert('Failed to add raw item. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">×</button>
        <h2>Add New Raw Item</h2>

        <div className="form-group">
          <label>Item Name *</label>
          <input
            name="item_name"
            placeholder="Enter Item Name"
            value={rawItemData.item_name}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Item Description</label>
          <textarea
            name="item_description"
            placeholder="Enter Description"
            value={rawItemData.item_description}
            onChange={handleChange}
            className="modal-input"
            style={{ resize: 'none', height: '80px' }}
          />
        </div>

        <div className="form-group">
          <label>Is Available *</label>
          <select
            name="is_available"
            value={rawItemData.is_available}
            onChange={handleChange}
            className="modal-input"
            required
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div className="form-group">
          <label>Status *</label>
          <select
            name="status"
            value={rawItemData.status}
            onChange={handleChange}
            className="modal-input"
            required
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <div className="form-group">
          <label>Image</label>
          <input
            name="image"
            placeholder="Enter Image URL"
            value={rawItemData.image}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <button onClick={handleAddRawItem} className="add-button">Add Raw Item</button>
      </div>
    </div>
  );
}

export default AddRawItem;
