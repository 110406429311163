import React, { useState } from 'react';
import { createDepartment } from '../../api/Department/department';
import '../../assets/css/userPage/userModal.css';

function DepartmentModal({ isOpen, onClose }) {
  const [newDepartment, setNewDepartment] = useState({
    name: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDepartment((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddDepartment = async () => {
    const departmentData = {
      name: newDepartment.name.trim(),
      description: newDepartment.description.trim(),
    };

    if (!departmentData.name) {
      alert('Please fill the required field: Name.');
      return;
    }

    try {
      await createDepartment(departmentData);
      alert('Department added successfully.');
      onClose();
    } catch (error) {
      console.error('Error adding department:', error.response?.data || error.message);
      alert(error.message || 'An error occurred while adding the department.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">×</button>
        <h2>Add New Department</h2>

        <div className="form-group">
          <label>Name</label>
          <input
            name="name"
            placeholder="Enter Department Name"
            value={newDepartment.name}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            placeholder="Enter Department Description"
            value={newDepartment.description}
            onChange={handleChange}
            className="modal-input"
          />
        </div>

        <button onClick={handleAddDepartment} className="add-button">
          Add Department
        </button>
      </div>
    </div>
  );
}

export default DepartmentModal;
