import apiClient from '../login/apiClient';

export const createRawItem = async (rawItemData) => {
  const userId = localStorage.getItem('userId');


  const rawItemPayload = {
    ...rawItemData,
    created_by: Number(userId),
  };

  try {
    const response = await apiClient.post('/api/rawItems', rawItemPayload);

    console.log('Raw Item created successfully:', response.data);
    return response.data;

  } catch (error) {
    console.error('Error creating raw item:', error.message);
    alert('Failed to create raw item. Please try again later.');
    return;
  }
};


export const searchRawItems = async (searchTerm, page = 1, pageSize = 10) => {

  if (!searchTerm) {
    console.error('Search term is missing.');
    return [];
  }

  try {
    const response = await apiClient.get('/api/rawItems/search', {
      params: { query: searchTerm, page, pageSize },
    });

    console.log('Raw Items fetched successfully:', response.data);
    return response.data;

  } catch (error) {
    console.error('Error searching raw items:', error.message);
    alert('Failed to fetch raw items. Please try again later.');
    return [];
  }
};


export const getAllRawItems = async (page = 1, pageSize = 10) => {
  try {
    const response = await apiClient.get('/api/rawItems/', {
      params: { page, pageSize },
    });

    return response.data || [];
  } catch (error) {
    console.error('Error fetching raw items:', error.message);
    throw new Error(error.response?.data?.message || 'Failed to fetch raw items');
  }
};


export const deleteRawItem = async (id) => {
  try {
    const response = await apiClient.delete(`/api/rawItems/${id}`);

    console.log('Raw Item deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting raw item:', error.message);
    alert('Failed to delete raw item. Please try again.');
    throw error;
  }
};

export const updateRawItem = async (id, updatedData) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    alert('Access token is missing.');
    return;
  }

  try {
    const response = await apiClient.put(`/api/rawItems/${id}`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Raw Item updated successfully:', response.data);
    return response.data;

  } catch (error) {
    handleError(error, 'Failed to update raw item');
  }
};


const handleError = (error, defaultMessage) => {
  if (error.response) {
    console.error('Server Error:', error.response.status, error.response.data);
    alert(`Server Error: ${error.response.data.message || defaultMessage}`);
    throw new Error(error.response.data.message || defaultMessage);

  } else if (error.request) {
    console.error('No response received:', error.request);
    alert('No response from the server. Please check your network connection.');
    throw new Error('No response from the server');

  } else {
    console.error('Error in setting up request:', error.message);
    alert(`Error in setting up request: ${error.message}`);
    throw new Error(error.message);
  }
};
